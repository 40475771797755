.otp-group {
  display: flex;
  width: 100%;
  /* max-width: 360px; */
  column-gap: 5px;
}

.otp-input {
  width: 100%;
  height: 50px;
  border: 1px solid #86b6ef;
  border-radius: 5px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
}