/* .general_logo-box {
  width: 100%;
  height: 93px;
  margin: 20px 0px 50px 20px;
} */

/* .general_logo {
  width: 51px;
  height: 58px;
} */

/* .general_logo-title {
  font-weight: 800;
  color: #272727;
  line-height: 31px;
  font-size: 25px;
} */

/* .general_logo-wrapper {
  margin-top: 30px;
  margin-left: 10px;
  display: flex;
  width: 148px;
  align-items: center;
} */

/* .general_img,
.bank-acc-img,
.convert-img,
.send-founds-img {
  margin: 0px 15px 0px 30px;
} */

/* .gi-link,
.ba-link,
.c-link,
.sf-link {
  text-decoration: none;
  font-weight: 600;
  font-size: 15px;
  color: #313131;
} */

/* .general-link,
.bank-link,
.convert-link,
.general_send-link {
  height: 42px;
  display: flex;
  align-items: center;
} */

/* .general-link:hover,
.bank-link:hover,
.convert-link:hover,
.general_send-link:hover {
  border-right: 6px solid #006ff4;
} */

/* .left-menu {
  height: 100vh;
  overflow: scroll;
} */

/* left-menu end */

.send-content {
  max-width: 877px;
  height: 100vh;
  padding: 40px 36px 0px 18px;
}

/* .addbene_title {
  font-weight: 700;
  font-size: 25px;
  color: #313131;
  padding-bottom: 20px;
} */

.addbene-card-box {
  width: 100%;
  height: auto;
  min-height: 450px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px 30px;
}

.send-content-text {
  display: flex;
}

.cnumber-text,
.iban-text {
  font-weight: 600 !important;
  font-size: 18px !important;
  border: none !important;
  background: none;
  font-weight: 600;
  font-size: 18px;
  border: none;
  background: none;
}

/* .enter-num-inp {
  width: 100% !important;
  height: 50px !important;
  background: #edf2f7 !important;
  border-radius: 5px !important;
  border: none !important;
  padding: 0.375rem 1.75rem !important;
  margin-bottom: 10px !important;
  font-weight: bold !important;
} */

.enter-num-inp.configure-limits-modal-input {
  width: 100% !important;
  height: 50px !important;
  background: #edf2f7 !important;
  border-radius: 5px !important;
  border: none !important;
  padding: 0.375rem 1.75rem !important;
  margin-bottom: 0 !important;
  font-weight: bold !important;
}

.enter-num-inp::placeholder,
.enter-amount-inp::placeholder,
.comment-inp::placeholder {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #cbcace !important;
}

.addbenef-form {
  width: 410px;
  margin: 20px auto;
}

.ent-num-inp-text,
.ent-amount-inp-text,
.comment-inp-text {
  font-weight: 600;
  font-size: 20px;
  color: #313131;
  margin-bottom: 2px;
  margin-left: 3px;
}

.btn-send {
  display: flex;
  justify-content: center;
}

.sendf-btn {
  width: 313px;
  height: 50px;
  background: #86b6ef;
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  margin-top: 22px;
}

.SendFunds-info-main {
  position: relative;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #0097F4 !important;
  border: 1px solid #0097F4 !important;
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
  color: #040404 !important;
}

.txtError {
  color: #ff1a1a;
}

/* Revamp Css  */
.countryCodeDropdown .form-select{
background-color: #0097F4;
color: #FFFFFF;
text-align: center;
}

.accountType .form-check-label , .companyOrPerson .form-check-label{
  font-size: 16px;
  font-weight: 400;
  color: #231F20;
  margin-bottom: 10px;
}
.addbeneficiary{
  margin: 1rem !important;
}


/* Revamp Css  */

@media screen and (max-width: 770px) {
  .addbenef-form{
    width: 100% !important;
  }
}
@media screen and (max-width: 576px) {
  .addbeneficiary{
    margin: 0.4rem !important;
  }
}
@media screen and (max-width: 460px) {
  .addbenef-form{
    width: 100% !important;
  }

  .MonthlyRevenueTracker{
    display: none;
  }
  
}