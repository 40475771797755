/* .modal-body{
  max-height: 100vh; 
  overflow-y: auto !important;
  
} */
.invoice-card-box {
  width: 100%;
  height: auto;
  min-height: 450px;
  background-color: white;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 16px;
  /* padding: 20px 20px 20px 20px; */
}

.invoice_title {
  font-weight: 600;
  font-size: 25px;
  color: #313131;
  padding-bottom: 10px;
}

.input_style {
  height: 25px;
  width: 115px !important;
  padding: 0px !important;
  background: #FFFFFF !important;
  border: none !important;
  font-weight: 600 !important;
}

.date_input_style {
  height: 25px;
  max-width: 150px!important;
  border-radius: 10px !important;
}

.datePicker {
  padding: 15px;
  width: 100%;
  border: none;
  background: #edf2f7;
  border-radius: 5px;
}

.invoice .invoice-form {
  width: '100%';
  margin: 20px auto;
}


.title {
  font-size: 24px;
  margin: 10px 0;
}

.groupBox {
  /* background: red; */
  /* padding: 5px; */
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.itemList-btnAddItem {
  margin-top: 10px;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  background: #86b6ef;
  color: white;
}

.itemList-listBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background: rgb(204, 0, 0); */
  padding: 10px 5px;
  border-radius: 5px;
  border: 2px solid #edf2f7;
  margin-top: 10px;
}

.itemList-input {
  border: none;
  width: 100%;
  background: #edf2f7;
  padding: 0.75rem 1.75rem;
  border-radius: 5px;
  color: black;
  font-weight: 600;
}

.itemList-title {
  font-weight: 600;
}

/* .itemList-desBox{
    width: 50%;
  }
  .itemList-quantityBox{
    width: 10%;
  }
  .itemList-rateBox{
    width: 10%;
  } */
.itemList-remove {
  color: rgb(251, 2, 2);
  font-weight: 600;
  cursor: pointer;
}

.invoiceDates{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 220px;
}
/* Revamp Css */
.invoice{
  padding: 20px 15px 15px 15px;
}
.invoiceType .form-select, .currencyList .form-select {
  border: 2px solid #D7F0FF;
    border-radius: 10px;
}
.borderTop{
  border-top: 1px solid #DEE2E6;
}
.billToInputs{
  border: 1px solid #D7F0FF !important;
}
.billToInputs::placeholder{
  color: #ADB6C0 !important;
}
.defaultSelectText{
  color: #ADB6C0 !important;
}
.currentDate{
  margin-bottom: 10px;
}
.borderLine{
  border-top: 1px solid #e9e7e7;
  border-bottom: 1px solid #e9e7e7;
  padding: 20px 0px;
  margin-bottom: 20px;
}

.editableInputBox input {
  border: 1px solid #D7F0FF;
}
.editableInputBox input::placeholder, .billToInputs::placeholder{
  color: #ADB6C0 !important;
  font-size: 12px;
}

.editableInputBox .inputGruopTextEndIcon{
  background-color: #D7F0FF;
  color: #006ff4;
    font-weight: 600;
}
.totalValue {
  color: #ADB6C0;
}
.invoiceType,
.currencyList,
.invoiceList{
  width: 100%;
}

.listOFInvoicesBox{
    background: #FFFFFF;
    border-radius: 9px;
    border: 2px solid #D7F0FF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    width: 100%;
    /* min-width: 120px !important; */
}
.listOFInvoices ul {
  list-style-type: none;
  background-color: #FFFFFF;
  border: 2px solid #d7f0ff;
  margin-top: 5px;
  border-radius: 9px;
  padding: 10px;
  width: 100%;
  position: absolute;
  z-index: 1;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.showInvoicesDropDown{
  display: block;  
}
.hideInvoicesDropDown{
  display: none;
}

.invoiceListModal thead tr td {
  background-color: #006ff4;
  color: #FFFFFF;
  font-size: 15px;
}
 .modal-content {
  margin-bottom: 30px;
}
.invoiceListModal thead tr td:first-child{
  border-top-left-radius: 8px ;
  border-bottom-left-radius: 8px ;
}
.invoiceListModal thead tr td:last-child{
  border-top-right-radius: 8px ;
  border-bottom-right-radius: 8px ;
}

/* Invoice preview Modall css */
.invoiceHeading{
font-size: 32px;
font-weight: 600;
}

.billed{
  font-weight: 600;
    color: #006FF4;
    margin-bottom: 0px !important;
}
.billedName{
  font-size: 19px;
  font-weight: 600;
}

.billedDetails {
  font-size: 16px;
  font-weight: 500;
}
/* Invoice preview Modall css */

.invoiceLabel{
  color: #ADB6C0;
  font-size: 14px;
}

.invoiceVal{
  color: #231F20;
  font-size: 14px;
}

.bankAccountDetailsTable table tbody tr td:nth-child(2) {
 font-weight: 600;
  padding-left: 100px !important;
}

.invoicedes table tbody tr td:nth-child(2) {
 font-weight: 600;
  padding-left: 40px !important;
}

.invoiceListModal tbody tr td, .invoiceTotalTbl tbody tr td{
  border: none;
  font-size: 14px;
}

.invoiceSubTotalLabel{
  font-weight: 600;
}
.invoiceSubTotalVal{
color: #ADB6C0;
}
.amountDueBox{
  background-color: #EBF5FB;
  padding: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.amountDueVal{
  margin: 0px;
  color: #000;
}
.accountDetailsHeading{
  font-size: 18px;
  font-weight: 600;
  color: #006FF4;
}

.invoiceHeader-Section, .borderTop{padding: 1.5rem;}
.dueDate{ white-space: nowrap;}





/* Revamp Css */

/* Media Queries Start */

@media (max-width: 1248px) {
  .itemList-listBox {
    flex-direction: column;
    padding: 10px 0px;
  }

  .itemList-desBox {
    border-bottom: 1px solid #edf2f7;
    padding: 0px 10px;
    /* background: red; */
    width: 100%;
  }

  .itemList-quantityBox {
    border-bottom: 1px solid #edf2f7;
    padding: 0px 10px;
    /* background: rgb(255, 242, 0); */
    width: 100%;
  }

  .itemList-rateBox {
    border-bottom: 1px solid #edf2f7;
    padding: 0px 10px;
    width: 100%;
    /* background: rgb(0, 166, 255); */
  }

  .invoice-heading {
    margin-left: 60px ;
    margin-top: 20px ;
  }

  @media (min-width: 320px) and (max-width: 1024px) {
    .invoice-heading {
      margin-left: 5px ;
      margin-top: 0px ;
    }
  }
}

.no-data-container{
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  height: auto;
}

.invoice-dec {
  width: 250px;
}
.invoice-qpa {
  width: 100px;
}

@media only screen and (max-width: 992px) {
  .invoice-th {
    font-size: 12px;
    padding: 4px !important;
  }
  .invoice-dec {
    font-size: 12px;
    padding: 6px !important;
    display: block;
    width: 150px !important;
    /* overflow: hidden; */
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    
  }
}

@media only screen and (max-width: 762px) {
  .modal-containter {
    margin-top: 40px;
  }
  .modal-content {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 576px) {
  .invoice{
    padding: 10px 10px 15px 10px;
  }
  .invoiceHeader-Section {
    flex-direction: row;
  }
  .invoiceHeader-Section, .borderTop{padding: 0.7rem;}
  .invoice-card-box{ padding-left: 0px !important; padding-right: 0px !important;}
  .top-selectors select{font-size: 11px !important;line-height: normal;}
  .listOFInvoicesBox{ padding: 10px 12px; font-size: 11px;  }
  .invoice-heading { margin-left: 0px ; font-size: 18px; }
  .invoiceNumber div span {min-width: fit-content;}
  .invoiceNumber div span, .invoiceNumber div input  {font-size: 10px !important;}
  .invoiceDates {align-items: end;}
  .currentDate{ font-size: 10px;}
  .dueDate{font-size: 10px;}
  .invoice-preview-logo{
    width: 80px !important;
    height: 28px !important;
    float: right;
  }
  .invoiceHeading {font-size: 18px;}
  .billedName, .billedDetails, .invoiceSubTotalLabel,.invoiceSubTotalVal   { font-size: 14px; }
  .invoiceListModal thead tr td{ font-size: 12px; padding: 8px !important;}
  .invoiceListModal tbody tr td{ font-size: 10px; padding: 5px 8px !important;}
  .invoiceListModal .headerDiv .modal-title {margin-left: 0px !important;}
  .preview-icon{width: 15px !important; height: 15px !important;}
  .invoice-list-table thead tr td {font-size: 10px !important; text-wrap: nowrap; vertical-align: middle;}
  .invoiceType svg, .currencyList svg  {width: 20px !important;}
  .main-col-invoice {padding: 0px !important;}
  .invoicedes table tbody tr td:nth-child(2) { padding-left: 50px !important; }
  .genPdf button, .sendPdf button { font-size: 14px;} 
  .modal-containter{ padding-top: 20px; }
  .modal-containter .modal-body{ padding: 15px 10px 15px 10px !important }
  .listOFInvoices ul li{font-size: 12px;}
  .listOFInvoices .load-more-text-recent{font-size: 11px; margin-right: 0px;}
  .listOFInvoices .no-data-container{font-size: 10px; margin-right: 0px; margin-left: 0px; }
  .listOFInvoices .no-data-container .no-recent-op {margin-top: 10px; margin-bottom: 10px; font-size: 10px !important; }
  .date_input_style { font-size: 10px !important; padding: 8px 5px 8px 6px !important; height: auto !important;}
  .footerDiv  {
    gap: 5px !important;
  }
.footerImage {
  height: 40px !important;
  width: 60px !important;
}
   

}

@media only screen and (max-width: 556px) {
  /* .invoiceHeader-Section {
    flex-direction: column;
  } */
}

@media only screen and (max-width: 430px) {
  
  .invoice_title{
    padding: 0;
    margin-left: 0;
  }
  /* .currentDate{
    width: 100%;
    margin-bottom: 10px;
  } */
  .dueDate{
    width: 100%;
  }
  .bankAccountDetailsTable table tbody tr td:nth-child(2) {
     padding-left: 35px !important;
   }
   
   .listOFInvoices ul{
    /* position: unset; */
   }
}



@media only screen and (max-width: 500px) {
  .invoice-th {
    padding: 1px !important;
  }
  .invoice-dec {
    padding: 2px !important;
    display: block;
    width: 89px !important; 
  }
  .invoice-sr {
    width: 15px !important; 
  }
}
@media only screen and (max-width: 395px) {
  .invoice-th {
    font-size: 10px;
    padding: 5px !important;
  }
  .invoice-dec {
    font-size: 10px;
    padding: 1px !important;
    display: block;
    width: 80px !important;
  }
  
  .currentDate{
    width: 100%;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 370px) {
  .invoiceHeader-Section {
    flex-direction: column;
  }
}

@media only screen and (max-width: 320px) {
  .modal-content {
    margin-bottom: 50px;
  }
}


