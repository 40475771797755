.payment-receiver-mainn {
  /* width: 185px; */
  /* height: 160px; */
  overflow: hidden;
  background: #ffffff;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: 0px 0px 6px 2px #001B3A0F;

  border-radius: 14px;
}

.image-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.image-rounded {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  /* width: 60px;
  height: 60px; */
}
.three-dot-icon {
  cursor: pointer;
  margin-top: 0px;
  color: #006FF4;
}
.accountNo-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
  color: #231F20;
}
.name-text-account {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 0;
  color: #ADB6C0;
}
/* .border-dashed-card {
  width: 185px;
  height: 170px;
  border: 2px dashed #4b9af4;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
} */
.no-data-container {
  display: flex;
  margin-right: 30px;
  height: 200px;
}
.detailLabel{
  font-size: 16px;
  font-weight: 600;
  color: #231F20;
}

.payment-card-column-main-new .payment-receiver-mainn .text-content .accountNo-text ,
.payment-card-column-main-new .payment-receiver-mainn .text-content .name-text-account{
  overflow: hidden;text-overflow: ellipsis; white-space: nowrap;
}

@media (max-width: 768px) {
  .border-dashed-card {margin-right: 0px !important;}
  .border-dashed-card img {width: 14px; height: 14px;}
 .payment-card-column-main-new .payment-receiver-mainn {padding: 8px 0px 10px 8px !important;}
 .payment-card-column-main-new .payment-receiver-mainn .image-icons img {width: 20px; height: 20px;}
 .payment-card-column-main-new .payment-receiver-mainn .image-icons svg {width: 24px; height: 18px;}
 .payment-card-column-main-new .payment-receiver-mainn .text-content .accountNo-text {font-size: 10px; margin-bottom: 0px; margin-top: 10px;}
 .payment-card-column-main-new .payment-receiver-mainn .text-content .name-text-account {font-size: 8px;  }
 .payment-card-column-main-new .payment-receiver-mainn .pay-now-btn-container {display: none !important;  }
}
@media (max-width: 576px) {
  .recipient-detail-modal-root table {margin-bottom: 0px;}
  .recipient-detail-modal-root div div .modalHeader .headerDiv .modal-title{ margin-left: 0px; font-size: 16px;}
  .recipient-detail-modal-root tbody tr td { font-size: 12px;}


 /* .payment-card-column-main-new .payment-receiver-mainn {padding: 10px !important;}
 .payment-card-column-main-new .payment-receiver-mainn .image-icons img {width: 20px; height: 20px;}
 .payment-card-column-main-new .payment-receiver-mainn .image-icons svg {width: 18px; height: 18px;}
 .payment-card-column-main-new .payment-receiver-mainn .text-content .accountNo-text {font-size: 10px; margin-bottom: 0px; margin-top: 10px;}
 .payment-card-column-main-new .payment-receiver-mainn .text-content .name-text-account {font-size: 8px;  } */
}
@media (max-width: 426px) {

  .border-dashed-card {
    margin-bottom: 20px;
  }
}
