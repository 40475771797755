.generalInfo-heading {
  width: 265px;
  height: 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 30px;
  color: #231F20;
}
.heading_title {
  padding-bottom: 20px;
}
.activity-chart {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  padding: 3.5rem;
}

/* .cardDetailsBox{
  padding-right: 30px !important;
} */

.Acc-switch {
  padding: 0 !important;
}

.accountSwitch{
  float: right;
}

@media (max-width: 430px) {
  .accountSwitch{
    width: 100%;
  }
  /* .cardDetailsBox{
    padding-right: 0px !important;
  } */
}