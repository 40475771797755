.OMW_logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.POAD_Heading {
    font-weight: bold;
    font-size: 30px;
}
.pos-modal-root .modal-title {
 margin-left: 14px !important;
}


@media screen and (max-width: 576px) {
    .pos-modal-root .modal-title { margin-left: 0px !important; }
    .pos-modal-root .OMW_logo img { width: 100px !important; margin-right: 15px !important; }
    .pos-modal-root .POAD_Heading { font-size: 16px !important; }

    .pos-modal-root p, 
    .pos-modal-root table tr td, 
    .pos-modal-root .date-created,
    .pos-modal-root .copyright,
    .pos-modal-root .desc { font-size: 12px !important; }

}
/* @media screen and (max-width: 430px) {
    .POAD_Heading {
        font-size: 26px;
    }
} */