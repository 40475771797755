/* .general_logo-box {
  width: 100%;
  height: 93px;
  margin: 20px 0px 50px 20px;
} */

/* .general_logo {
  width: 51px;
  height: 58px;
} */

/* .general_logo-title {
  font-weight: 800;
  color: #272727;
  line-height: 31px;
  font-size: 25px;
} */
/* .left-menu {
  height: 100vh;
  overflow: scroll;
  position: fixed;
} */


.mr-xl-1 {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

/* .general_logo-wrapper {
  margin-top: 30px;
  margin-left: 10px;
  display: flex;
  width: 148px;
  align-items: center;
} */

/* .general_img,
.bank-acc-img,
.convert-img,
.send-founds-img {
  margin: 0px 15px 0px 30px;
} */

/* .gi-link,
.ba-link,
.c-link,
.sf-link {
  text-decoration: none;
  font-weight: 600;
  font-size: 15px;
  color: #313131;
} */

/* .general-link,
.bank-link,
.convert-link,
.general_send-link {
  height: 42px;
  display: flex;
  align-items: center;
} */

/* .general-link:hover,
.bank-link:hover,
.convert-link:hover,
.general_send-link:hover {
  border-right: 6px solid #006ff4;
} */

/* .left-menu {
  height: 100vh;
  overflow: scroll;
} */

/* left-menu end */

/* .send-content {
  max-width: 877px;
  height: 100vh;
  padding: 40px 36px 0px 18px;
} */

/* .sendf_title {
  font-weight: 700;
  font-size: 25px;
  color: #313131;
  padding-bottom: 20px;
} */

/* .send-card-box {
  width: 100%;
  height: auto;
  min-height: 450px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px 30px;
} */

/* .send-content-text {
  display: flex;
} */

/* .cnumber-text,
.iban-text {
  font-weight: 600 !important;
  font-size: 18px !important;
  border: none !important;
  background: none;
  font-weight: 600;
  font-size: 18px;
  border: none;
  background: none;
} */

/* .enter-num-inp {
  width: 100% !important;
  height: 50px !important;
  background: #edf2f7 !important;
  border-radius: 5px !important;
  border: none !important;
  padding: 0.375rem 1.75rem !important;
  margin-bottom: 10px !important;
} */

/* .enter-num-inp .configure-limits-modal-input {
  width: 100% !important;
  height: 50px !important;
  background: #edf2f7 !important;
  border-radius: 5px !important;
  border: none !important;
  padding: 0.375rem 1.75rem !important;
  margin-bottom: 0 !important;
} */

/* .enter-num-inp::placeholder,
.enter-amount-inp::placeholder,
.comment-inp::placeholder {
  font-weight: 500 !important;
  font-size: 1rem !important;
  color: #cbcace !important;
} */

/* .sendf-form {
  width: 470px;
  margin: 20px auto;
} */

/* .ent-num-inp-text,
.ent-amount-inp-text,
.comment-inp-text {
  font-weight: 600;
  font-size: 20px;
  color: #313131;
  margin-bottom: 2px;
  margin-left: 3px;
} */

/* .btn-send {
  display: flex;
  justify-content: center;
} */

/* .sendf-btn {
  width: 313px;
  height: 50px;
  background: #86b6ef;
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  margin-top: 22px;
} */

/* .SendFunds-info-main {
  position: relative;
} */

.generalInfo-heading {
  width: 265px;
  height: 30px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;

  color: #231F20;
}

/* .verifyPayment-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
} */

/* .verication-payment-text {
  font-weight: 500;
  font-size: 20px;
  color: #313131;
} */

.verify-titlee {
  display: flex;
  justify-content: center;
}

/* .enter-inp {
  width: 300px;
  height: 50px;
  background: #edf2f7;
  border: none;
  max-width: 100%;
  border-radius: 5px;
  font-family: inherit;
  padding: 0.375rem 1.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 30px;
} */

/* .enter-otp-inp {
  width: 470px;
  height: 50px;
  border: none;
  padding: 0.375rem 1.75rem;
  margin-bottom: 30px;
} */

/* .enter-inp::placeholder {
  font-weight: 500;
  font-size: 20px;
  color: #cbcace;
} */

/* .send-btn {
  height: 50px;
  width: 80%;
  max-width: 100%;
  background-color: #86b6ef;
  border-radius: 5px;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  border: none;
  margin-bottom: 30px;
  cursor: pointer;
} */

/* .send-row {
  display: flex;
  justify-content: space-evenly;
} */

/* .receipt__data {
  overflow: hidden;
  padding: 20px 0px 0px 0px;
  border-radius: 20px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  margin: 10px 0 0px;
} */

/* .logo-receipt {
  display: flex;
  justify-content: flex-start;
} */

/* .fund-send-con {
  margin-bottom: 10px;
  padding-right: 0 !important;
}

.fund-send-con p {
  font-size: 13px;
  margin-bottom: 0px;
  font-weight: 500;
}

.fund-send-con h6 {
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: bolder;
} */

.circle-box-receipt {
  width: 100%;
  height: 20px;
  position: relative;
}

.blue-circle-receipt {
  /* width: 422px;
  height: 500px;
  border-radius: 50%;
  background-color: #70ade6;
  position: absolute;
  bottom: 0px;
  z-index: 0;
  opacity: 0.9;
  left: -190px;
  bottom: -290px; */
  width: 487px;
  height: 426px;
  border-radius: 50%;
  background-color: #70ade6;
  position: absolute;
  bottom: 0px;
  z-index: 0;
  opacity: 0.9;
  left: -190px;
  bottom: -290px;
}

.white-circle-receipt {
  /* width: 341px;
  height: 543px;
  background-color: #b1c9ff;
  opacity: 0.3;
  border-radius: 49%;
  position: absolute;
  right: -117px;
  bottom: -304px;
  z-index: 0; */
  width: 422px;
  height: 450px;
  background-color: #b1c9ff;
  opacity: 0.3;
  border-radius: 49%;
  position: absolute;
  right: -117px;
  bottom: -304px;
  z-index: 0;
}

/* .col-6 {
  z-index: 3;
} */

/* .verify-titlee h3 {
  margin-bottom: 10px;
  font-weight: bold;
}

.verify-titlee p {
  text-align: center;
  font-weight: bold;
  margin-bottom: 0px !important;
}

.verify-titlee-icon {
  align-self: center;
  margin-bottom: 10px;
}

.verify-titlee {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  z-index: 5;
} */

/* .card-send-msg {
  padding: 20px;
  margin-bottom: 10px;
  background-color: #86b6ef !important;
  overflow: hidden;
  height: 200px !important;
} */

/* .card-send-dat {
  padding: 20px;
  margin-bottom: 10px;
  background-color: #86b6ef !important;
  overflow: hidden;
} */

/* .card-send-dat hr {
  margin: 0;
} */

/* .send-btn-download {
  width: 130px;
  background-color: #86b6ef;
  border-radius: 5px;
  font-size: 14px;
  color: #ffffff;
  height: 34px;
  border: none;
  margin-bottom: 0px;
  cursor: pointer;
} */

/* .receipt-btn hr {
  margin-bottom: 0.5rem;
}

.receipt-btn {
  display: flex;
  justify-content: end;
} */

/* .receipt-card-icon {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
} */

/* .receipt-status {
  z-index: 5;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
} */

/* .modal-title {
  font-weight: bolder !important;
} */

/* .send-otp-btn {
  height: "auto";
  width: 470px;
  padding: 10px;
  max-width: fit-content;
  max-height: fit-content;
  background-color: #86b6ef;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  border: none;
  margin-bottom: 30px;
  cursor: pointer;
} */
.date_style{
  height: 40px !important;
  max-width: 200px !important;
}


/* Revamp Css */

.accordionTab {
  border-radius: 15px !important;
}

.accordionTab .accordion-item {
  border: 2px solid #D7F0FF;
  border-radius: 15px !important;
}

.accordionTab .accordion-button {
  border-radius: 15px !important;
  border: 2px solid #D7F0FF;
  background-color: #FFFFFF !important;
  color: #ADB6C0 !important;
  box-shadow: none !important;
}
.accordionTab .accordion-button:focus {
  color: #ADB6C0;
  border:2px solid #FFFFFF;
}

.paymentReceiptModalHeading{
  font-size: 20px;
    font-weight: bold;
    color: #231F20;
}
.SendFunds{
  margin: 1rem !important;
}
@media screen and (max-width: 576px) {
  .ver-right-title{font-size: 20px !important;}
  .SendFunds{margin: 0.4rem !important;}
  .modal-dialog{ margin-top: 100px !important; }
  .sendFund-2FA-modal img {
    width: 70px;
    height: 70px;
  }

}
