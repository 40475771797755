.generalInfo-heading {
  width: 265px;
  height: 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 30px;
  color: #231F20;
}
.heading_title {
  padding-bottom: 20px;
}
.inner__content{
  /* padding: 20px 10px 20px 0px !important; */
  /* margin-left: 255px !important; */
  
}
.page-container{
  padding: 0px 15px 0px 15px ;
}

.responsive-font {
  font-weight: 600;
  margin-top: 20px;
  font-family: "Montserrat";
  font-size: 24px;
  color: #231F20;
}

.responsive-header {
  font-weight: 600;
  margin-top: 10px;
  font-family: "Montserrat";
  font-size: 20px;
  color: #231F20;
}

.text-content-card-general1{
  display: flex;
  flex-direction: column;
  /* padding-bottom: 18px; */
}

.custom-tooltip {
  /* background: linear-gradient(90deg, #00BFFF 0%, #1E90FF 100%); */
  background: transparent;
  color: white;
  padding: 5px 10px;
  /* border-radius: 5px; */
  font-size: 18px;
  font-family: "Montserrat";
  font-weight: 600;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}


.responsive-tab-content {
  /* textAlign: 'left', fontFamily: "Lato", fontWeight: 400, fontSize: "18px" */
  text-align: left;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 18px;
}

.merchantDiv {
  display: flex;
  align-items: center;
  /* margin-right: 60px; */
  margin-top: 15px;
  width: 25%;
  position: relative;
}
.merchantDiv p {
 font-weight: 500;
 white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 40px;
  /* width: 130px; */
}

.merchantText {
  margin-right: 10px;
}



.MuiTableRow-root {
  border-bottom: none !important;
  border-top: none !important;
}

.MuiTableCell-root {
  border-bottom: none !important;
}

.MuiTableHead-root .MuiTableRow-root {
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  height: 65px !important;
}

.MuiTableHead-root .MuiTableCell-root {
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}
.card-general-mainn{
   box-shadow: 0px 0px 6px 2px #001B3A0F;
   /* height: 180px; */
   background-color: white;
   border-radius: 14px;
   padding-top: 18px;
   padding-left: 18px;
}
/* .card-inner{
  padding-top: 0px;
  padding-left: 0px;

} */
.card-mainbox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;

}
.currency-buttons{
  display: flex;
  gap: 16px;
}
.btn-disable{
  color: #ADB6C0;
  background-color: transparent;
  border: none;
  font-size: 20px;
  font-weight: 600;
  
}
.active{
  color: #006FF4;
  background-color: transparent;
  border: none;
  font-size: 20px;
  font-weight: 600;


}
.card-mainbox-icon{
  height: 40px;
  width: 40px;
  background: #D7F0FF;
  background: #d7f0ff;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  max-width: 50px !important;
}
.card-icon{
  
}
.card-title {
  font-size: 18px;
  font-weight: 500;
   color: #ADB6C0;
   margin-top: 12px;
   margin-bottom: 6px;
   /* line-height: 50px */
}
.card-balance {
  display: 'inline-block';
  font-size: 18px;
   font-weight: 600;
   color: #191919;
   /* margin-top: 10px; */

}
.cards-column{
  z-index: unset;
}

.legend-ul{
   display: flex;
   justify-content: flex-end;
   /* padding-bottom:10px; */
   /* margin-left:0px; */
   /* margin-right: 20px; */
  /* padding-top:10px; */
}
.iban-numbers {
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
  color: #191919;
  /* margin-left: -4px; */
  /* margin-bottom: 5px; */
  padding-bottom: 5px;
}
.card-indicator {
  font-weight: 600;
  font-size: 11px;
  margin-top: 5px;
  /* margin-left: -4px !important; */
  padding-bottom: 5px !important;
}
.responsive-container-column{
  padding-left: 35px;
}


/* Media query for smaller screens */
@media (max-width: 1024px) {
  .merchantDiv {
    width: 30%;
  }
}
@media (max-width: 992px) {
  .merchantDiv {
    width: 50%;
  }
}
@media (max-width: 600px) {
  .merchantDiv {
    flex-direction: column;
    align-items: flex-start;
    /* padding-left: 10px; */
    width: 100%;
  }
  .merchantText {
    margin-right: 0;
    margin-bottom: 10px; /* Optional: Add some space between the elements */
  }
}
.card-container{
  padding: 5px;
  /* position: relative; */
   /* margin-top: 20px; */
}
.mui-datatable-container table {
  width: 100% !important;
  overflow-x: auto;
}
.mui-datatable-container .tss-11quiee-MUIDataTable-paper {
  box-shadow: none;
}
.mui-datatable-container table thead tr th {
  white-space: nowrap;
}

@media (max-width: 576px) {
  .page-container{
    padding: 0px 5px 0px 5px ;
  }
  .responsive-header {
    font-size: 12px;
  }
  .responsive-tab-content {
    font-size: 10px;
  }
  .responsive-font {
    font-size: 16px;
    margin-top: 10px;

  }
  .card-container{
    padding: 2px;
   /* margin-top: 5px; */
  }
  .cards-column{
    /* padding: 0px !important; */
    z-index: unset;
  }
  
  
  .card-general-mainn{
    /* height: 133px; */
    height: auto;
    padding: 0px;
 }
 .card-inner{
  padding-top: 8px;
  padding-left: 12px;
}
.card-inner div .iban-numbers {
  margin-bottom: 0px;
}
.card-mainbox-icon{
   height: auto;
   width: 33px;
   margin-top:4px
}
.card-title {
  font-size: 14px;
   margin-top: 8px;
   margin-bottom: 3px;
}
.card-balance{
  font-size: 14px;
  /* line-height: 30px; */
}

.mui-datatable-container table tr th ,
.mui-datatable-container table tr td {
  padding: 5px;
}
/* .cards-row{
  padding-left: 8px;
  padding-right: 8px;
} */
/* Line chard media queries */
.chart-main-container{
  padding: 0px 0px 0px 0px !important;
  margin-left: -3px;
  margin-right: -3px;
  height: 442px !important;
  
}
.legend-ul{
  justify-content: flex-start;
  padding:5px 0px 5px 21px;
}
/* Recent transactions */
.MuiTableHead-root .MuiTableRow-root {
  height: 45px !important;
}

  
}
