.mainLayoutSection {
    /* display: flex;
    justify-content: space-between; */
}

.mainLayout_Leftsidebar {
  display: block;

    height: 100vh;
    position: fixed;
    width: 235px;
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
    z-index: 2;
}

.mainLayout_HeaderAndContent {
    /* width: 80%; */
    /* width: calc(250px - 100%); */
    /* margin-left: auto; */
}

.mainLayout_Header {
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.1) 16px 0px 0px;
    width: calc(100% - 235px);
    margin-left: auto;
    position: sticky;
    top: 0;
    z-index: 1;

    /* position: fixed; */
    /* width: 100%; */
    /* z-index: 1; */
}
.mainLayout_Content {
    /* margin-top: 35px; */
    width: calc(100% - 235px);
    margin-left: auto;
    padding: 10px;
}

@media (max-width: 1025px) {
    .mainLayout_Content {
        width: 100%;
    }

    .mainLayout_Leftsidebar {
        display: none;
    }
    .mainLayout_Content {
        width: 100%;
    }
    .mainLayout_Header {
        width: 100%;
    }
    /* .mainLayout_Header {
        width: 100%;
    } */
}

@media (max-width: 760px) {
    /* .mainLayout_Leftsidebar {
        display: none;
    } */

    /* .mainLayout_Content {
        width: 100%;
    } */

    /* .mainLayout_Header {
        width: 100%;
    } */
}