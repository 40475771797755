.general_logo-box {
  width: 100%;
  height: 93px;
  margin: 20px 0px 10px 20px;
  margin-top: 0;
}
.general_logo {
  width: 61px;
  height: 58px;
}
/* .general_logo-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 25.3425px;
  line-height: 31px;
  color: #272727;
} */

/* .general_logo-wrapper {
  margin-top: 30px;
  margin-left: 10px;
  display: flex;
  width: 148px;
  align-items: center;
} */
/* ul.nav-menu_link-wrapper {
  padding: 0;
  list-style: none;
} */
/* .general_nav-menu p {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
  margin: 0;
  color: #ADB6C0;
} */

/* .general_nav-menu li {
  cursor: pointer;
} */

.tabsIcon {
  margin: 0px 15px 0px 30px;
}
/* .add-recipient-img {
  margin: 0px 15px 0px 30px;
  width: 23px;
} */
/* .tabsLink {
  text-decoration: none;
  font-size: 15px;
  color: #c0c7cf;
} */

/* .general-link,
.bank-link,
.convert-link,
.general_send-link {
  height: 42px;
  display: flex;
  align-items: center;
}
.general-link:hover,
.bank-link:hover,
.convert-link:hover,
.general_send-link:hover {
  border-right: 6px solid #006ff4;
} */

.NavList-Li{
  height: 42px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 12px 0px;
}
.NavList-Li:hover{
  border-right: 6px solid #006ff4 !important;
}

.lang-selector {
  /* position: fixed !important;
  bottom: 40px;
  left: 2.5%; */
  /* margin-left: 30px; */

}
/* .cstm-btn {
  display: flex;
} */

/* Revmap Sidebar Css */
.leftSidebar-NavAnddropdown{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 80%;
}

.left-menu{
  height: 100%;
  overflow-x: hidden;
  overflow-y: clip;
  position: relative;
}
.general_nav-menu{
  height: 100vh;
  /* overflow-y: scroll; */
  overflow-x: hidden;
}

.leftSidebarFooter{
    position: absolute;
    bottom: 0px;
    left: 17px;
    width: 100%;
    padding: 16px 0px;
    background-color: #fff;
    z-index: 999999;
}
.leftSidebarPrivacyPolicy {
  display: flex;
  justify-content: start;
  align-items: baseline;
}
.leftSidebarPrivacyPolicy .privacyPolicyPara {
  margin-right: 5px;
  cursor: pointer;
}
.leftSidebarPrivacyPolicy .feesPara {
  margin-left: 5px;
  cursor: pointer;
}
.leftSidebarCopyRights , .leftSidebarPrivacyPolicy p{
color: #006ff4;
margin: 0;
font-size: 12px;
}
.leftSidebarCopyRights{
  cursor: pointer;
  color: #adb6c0;
}
.tabLink{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  margin: 0;
  color: #ADB6C0;
}

@media (max-width: 576px) {
 .left-menu .general_logo-box { text-align: center; margin: 0px; height: 80px; }
 .left-menu .drawer-logo { width: 114px !important; margin-left: 0px !important; margin-right: 15px; }
 .left-menu .tabsIcon  { margin: 0px 10px 0px 0px; width: 18px;}
 .leftSidebar-NavAnddropdown{height: 75%;}
 .leftSidebarFooter{ left: 0px; padding-bottom: 0px; }
 .leftSidebarFooter p{ font-size: 14px; }
}

/* @media (max-width: 426px) {
  .leftSidebarFooter p{
    font-size: 14px;
    }
} */