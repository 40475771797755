.exchange-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;

  color: #231F20;
}
.exchange-rates-box {
  display: block;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100%;
  height: auto;
  min-height: 180px;
  padding: 10px;
}
.exchange-content {
  display: flex;
  justify-content: space-between;
}
ul.ex_rates_list {
  list-style: none;
}
ul.ex_rates_list div {
  padding: 3px 6px !important;
}
ul.ex_rates_list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
}
li div img.img-flag {
  border-radius: 50%;
  width: 26px;
  height: 26px;
}
.currency-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin-top: 30px;

  color: #313131;
}
.currency-amount {
  /* font-style: normal; */
  font-size: 17px;
  font-weight: 600;
  /* identical to box height */
  margin: 0px;
  color: #231F20;
}
.load-more-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;

  color: #313131;
  margin-bottom: 10px;
  cursor: pointer;
}
.main-all-currencies {
  display: flex;
  justify-content: center;
}
.exchange-loading {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.loadingg {
  margin-top: 50px;
}
.message-container {
  display: flex;
  justify-content: center;
}
.message-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  margin-top: 20px;
  margin-left: 5px;
  color: #313131;
}
.valid-for {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.valid-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;

  color: #ADB6C0;
  margin-bottom: 10px;
}
.refresh-icon-container {
  display: flex;
  justify-content: space-between;
  padding: 4px 12px;
  align-items: center;
}
.refreshIcon {
  cursor: pointer;
}
.exchangeRateCountryFlag span {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-position: center;
}

/* Curency flag and rates css */
.countryCode{
  margin-bottom: 0;
  margin-left: 15px;
  margin-top: 5px;
  font-size: 17px;
  font-weight: 500;
  color: #231F20;
}
/* Curency flag and rates css */

@media screen and (max-width: 427px) {
.exchange-rates-box{
  margin-top: 15px;
  display: none;
  }
}
@media (max-width: 375px) {
  .exchange-text {
  font-size: 21px;
  }
}