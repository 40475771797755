#showPin {
  letter-spacing: 20px;
  font-size: 18px;
  margin: 0;
  padding: 0 0 0 20px;
  font-family: Montserrat;
  color: #231F20;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pinContainer {
  display: flex;
  justify-content: left;
  align-items: center;
}

.pinFrame {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #D7F0FF;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.pinDigit {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-size: 1.5rem;
}