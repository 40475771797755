.btnAddNewCard {
  font-size: 15px;
  padding: 0 3px;
  margin: 5px 0;
}

.alignRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-content {
  border-radius: 16px !important;
  border: none !important;
}

.cardsHeader {
  display: flex;
  align-items: center;
}

.goBackIcon {
  margin-right: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.goBackIcon:hover {
  transform: scale(1.1);
}

.goBackIcon svg {
  font-size: 30px;
  cursor: pointer;
}

.cardsHeader h2 {
  margin: 0px;
  width: max-content;
}

.bankAccountCard_header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
}

.applyForCard {
  border: none;
  border-radius: 6px;
  padding: 5px;
  background: #0B5ED7;
  color: #ffffff;
}

.generalInfo-heading {
  width: 265px;
  height: 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #231F20;
}

.heading_title {
  padding-bottom: 20px;
}

.countryFlagButtonPhoneInput :hover {
  background-color: #f1f1f1;
}

/* Card Css start */
.cardsParentContainer {
  background: #FFF;
  margin-bottom: 24px;
  border-radius: 16px;
  width: auto;
  padding-top: 24px;
  padding-bottom: 24px;

}

.allCardsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 10px;
  /* height: 245px; */
}

.noCardsMessageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.noCardsMessageInnerContainer {
  display: flex;
  cursor: pointer;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.cardDiv {
  display: flex;
  height: 260px;
  width: 100%;
}

/* .cardDiv:first-child:hover{
  margin-left: 6px;
} */

.cardContainer {
  margin: 5px 20px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
}

.cardGeneralInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardGeneralInfo p,
.maskedCardNumberDiv p,
.cardHolderDiv p {
  margin-bottom: 0;
}

.availableBalanceText {
  color: #ADB6C0;
  font-weight: 500;
  font-size: 14px;
}

.amountCurrencyText {
  font-weight: 600;
  font-size: 20px;
  color: #231F20;
}

.cardTypeText {
  color: #ADB6C0;
  font-size: 14px;
}

.cardStatusText {
  color: #ADB6C0;
  font-size: 14px;
  margin-bottom: 0;
}

.maskedCardNumberDiv {
  display: flex;
  /* justify-content: center; */
  margin: 15px 0px;
}

.maskedCardNumber {
  font-family: "Courier New", monospace;
  letter-spacing: 0.07em;
  font-size: 18px;
}

.cardHolderDiv {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.visaVector {
  width: 49px;
  height: 28px;
  margin-right: 4px;
}

.cardPrimaryActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.cardDKK {
  background-color: #90c6f3;
}

.cardSEK {
  background-color: #d7c872;
}

.cardBody {
  /* background-color: rgb(255, 255, 255); */
  width: 255px;
  padding: 20px 10px 10px 13px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  color: #ffffff;
  margin: 5px 0px 10px 0px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s, box-shadow 0.3s;

  background-image: url("../../assets/images/cardAssets/omwVirtualCardTemplate.svg");
  /* Adjust as needed */
  background-size: cover;
  background-repeat: no-repeat;
}

.physicalCardBody {
  width: 230px;
  cursor: pointer;
  color: #ffffff;
  margin: 5px 0px 10px 0px;
  transition: transform 0.3s, box-shadow 0.3s;
  background-size: cover;
  background-repeat: no-repeat;
  /* border-radius: 30px; */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
}

.cardTemplateImage {
  width: 250px;
  padding: 0;
  cursor: pointer;
  margin: 5px 0px 10px 0px;
  transition: transform 0.3s, box-shadow 0.3s;
  /* border: 1px solid rgba(0, 0, 0, 0.12); */
  border-radius: 14px;
  box-shadow: 10px 0px 15px 5px rgba(0, 27, 58, 0.1);
}

.main-cards-content {
  /* display: flex; */
  /* flex-direction: column; */
  min-height: 100vh;
  /* overflow: hidden; */
}

.terms-and-conditions {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  color: gray;
  /* left: 0;
  right: 0; */
}

.cardsSkeletonContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: default;
}

.cardBodySkeleton {
  height: 160px;
  width: 250px;
  border: none;
  border-radius: 12px;
}

.cardBody:hover,
.cardBody.selectedCard {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.physicalCardBody:hover,
.physicalCardBody.selectedCard {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}



.cardTemplateImage:hover,
.cardTemplateImage.selectedCard {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

#cartTemp:img:hover {
  margin-left: 8px;
}

.cardBody-header {
  align-items: baseline;
  margin-bottom: 10px;
}

/* Card Css end */

/* Transaction Css start*/
.transactionHeaderDiv {
  /* margin-top: 20px !important; */
}

.transactionTableheading {
  /* width: 265px;  */
  height: 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  /* line-height: 30px; */
  color: #231F20;
}

.view-all {
  display: flex;
  width: 100px;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 15px;
  font-weight: 600 !important;
  cursor: pointer;
  border-radius: 0px 0px 10px 10px;
  align-items: center;
  justify-content: center;
  /* color: #006FF4; */
  /* text-decoration: underline; */
}

.view-all svg {
  margin-left: 6px;
  font-size: 20px;
}

.view-all:hover {
  transition: transform 0.3s, box-shadow 0.3s;
  transform: scale(1.1);
  box-shadow: 0px 10px 9px rgba(0, 0, 0, 0.1);
}

.transactionTableDiv {
  /* overflow-x: scroll; */
  /* ihave commend below code */
  /* overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
}

.transactionDiv {
  background: #ffffff;
  padding: 15px;
  border-radius: 16px;
}

.transactionTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.transactionTable thead tr td:nth-child(1) {
  width: 6%;
}

.transactionTable thead tr td:nth-child(2) {
  width: 50%;
}

.transactionTable thead tr td:nth-child(3) {
  width: 20%;
}

.transactionTable thead tr td:nth-child(4),
.transactionTable thead tr td:nth-child(5) {
  width: 12%;
}

.transactionTable thead tr td {
  padding: 10px 5px;
  border-bottom: 2px solid #e5ebf4;
  font-weight: 600;
}

.transactionTableBody tr td {
  padding: 10px;
  text-align: left;
  white-space: nowrap;
  /* commented by me */
  /* overflow: hidden; */

  /* text-overflow: ellipsis; */
}

.tableHeadings {
  font-weight: 600;
}

.transactionData_MerchantName {
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 400px;
}

.transactionTableBody .transactionData {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 6px;
  color: #ADB6C0;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.transactionTableBody .transactionAmounts {
  font-weight: 600;
  margin-bottom: 0;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
}

.transactionTableRow:hover {
  transition: box-shadow 0.2s;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.amountAdded {
  color: #05D401;
}

.amountDeducted {
  color: #f40f00;
}

/* Transaction Css end*/

/* Payment Receiver css start */
.paymentReceiver {
  margin-top: 20px !important;
}

.PR-heading {
  width: 280px !important;
}

/* Payment Receiver css end */

.cards-legal-modal-text {
  text-align: justify;
}

/* Add Amount Modal Css start */
.addMoney-AmountToTopUp {
  display: flex;
  justify-content: space-between;
}

.addMoney-AmountToTopUp-AmountInput {
  margin-left: 15px;
}

/* Add Amount Modal Css end */

/* PENDING KYB SCREEN CSS::: START */
.cardsKYBScreen {
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* PENDING KYB SCREEN CSS::: END */

/* PHYSICAL CARD ACTIVATION T&C'S LINKS::: START */
.termsCondsLink {
  font-size: 14px;
  cursor: pointer;
}

.termsCondsLink a {
  text-decoration: none;
  color: black;
}

.termsCondsLink:hover {
  text-decoration: underline;
}

/* PHYSICAL CARD ACTIVATION T&C'S LINKS::: END */


@media screen and (max-width: 1440px) {
  .cardBody {
    width: 256px;
  }


  .physicalCardBody {
    width: 256px;
  }
}

@media screen and (max-width: 1300px) {
  .transactionTable thead tr td {
    /* font-size: 15px; */
    /* padding: 8px 4px; */
  }

  .transactionTableBody .transactionAmounts {
    /* font-size: 16px; */
  }

}

@media screen and (max-width: 1200px) {
  /* .transactionTable thead tr td {
    font-size: 14px;
    padding: 8px 4px;
  }
  .transactionTableBody tr td {
    padding:8px 6px;
  }
  
  .transactionData_MerchantName {
    font-size: 14px;
    margin-left: 10px;
    margin-top: 8px;
  }
  .transactionTableBody .transactionData {
    font-size: 14px;
    margin-top: 7px;
  }
  .transactionTableBody .transactionAmounts{
    font-size: 14px;
    margin-top: 3px;
  } */
}

/* @media screen and (max-width: 1100px) {
  .transactionTable thead tr td {
    font-size: 12px;
    padding: 8px 3px;
  }
  .icon-image{
    width: 30px;
  }
  .transactionData_MerchantName {
    font-size: 12px;
    margin-left: 4px;
    margin-top: 5px;
  }
  .transactionTableBody .transactionData {
    font-size: 12px;
    margin-top: 5px;

  }

  .transactionTableBody .transactionAmounts{
    font-size: 12px;
  }
} */



@media screen and (max-width: 1024px) {

  /* .transactionTable thead tr td {
    font-size: 10px;
    padding: 8px 1px;
  }
  .transactionData_MerchantName{
    font-size: 10px;
    margin-left: 6px;
    margin-top: 7px;
  }
  .transactionTableBody .transactionData {
    font-size: 10px;
  }
  .transactionTableBody .transactionAmounts{
    font-size: 10px;
    margin-top: 6px;
  } */
  .cardBody {
    width: 243px;
  }

  .maskedCardNumber {
    font-size: 17px;
  }

  .cardDetailsLabel {
    font-size: 11px;
  }

  .physicalCardBody {
    width: 243px;
  }

}

/* @media (max-width: 900px) {
  .icon-image {
    width: 24px;
  }
} */
/* @media (max-width: 850px) {
  .transactionData_MerchantName{
    margin-left: 10px;
    margin-top: 6px;
  }
} */

@media (max-width: 768px) {
  .goBackIcon svg {
    font-size: 17px;
  }

  .cardDiv {
    height: 245px px;
  }

  .cardBody {
    width: 240px;
    padding: 13px 10px 13px 10px;
  }

  .transactionTableheading {
    font-size: 20px;
  }

  .physicalCardBody {
    width: 240px;
  }

  /* .cardTemplateImage {
    height: 149px;
  } */

  /* .transactionTableBody .transactionData {
    font-size: 12px;
  } */

  .card-settings-modal-height .modal-content {
    margin-top: 50px;
  }
  .btnAddNewCard {
    font-size: 12px;
  }
  .addCardIcon {
    font-size: 16px !important;
  }
}

@media (max-width: 760px) {
  .transactionTableBody tr td {
    padding: 10px 5px 10px 5px;
  }

  .icon-image {
    width: 35px;
  }

  .transactionData_MerchantName {
    width: 100%;
    margin-top: 4px;
  }

  .transactionTableBody .transactionAmounts {
    margin-top: 2px;
  }
}


@media (max-width: 320px) {
  .cardBody {
    width: 248px;
    padding: 13px 10px 13px 15px;
  }

  .visaVector {
    margin-right: 5px;
  }

  .physicalCardBody {
    width: 248px;
  }

  .cardTemplateImage {
    height: 150px;
  }

  .card-settings-modal-height .modal-content {
    margin-top: 30px;
  }

  .currencyFlag {
    font-size: 15px;
  }
}

/* @media (min-width: 1440px) {
  .visaVector{
    margin-right: 8px;
  }
} */

.slide{
  animation: slide 0.15s ease-in-out forwards ;
}

.slide-back{
  animation: slide-back 0.15s ease-in-out forwards ;
}

.fade-in {
  opacity: 0; /* Ensure the element starts as invisible */
  animation: fade-in 0.30s ease-in-out forwards;
}

@keyframes slide{
  0%{transform: translateY(200px);}
  100%{transform: translateY(0%);}
}

@keyframes slide-back{
  0%{transform: translateY(-200px);}
  100%{transform: translateY(0%);}
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.card-selection-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.card-option {
  cursor: pointer;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 10px;
  text-align: center;
  width: 45%;
  transition: border-color 0.3s ease, transform 0.3s, box-shadow 0.3s;
}

.card-option:hover {
  border-color: #007bff;
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.card-option.selected {
  border-color: #007bff;
  background-color: #d7f0ff;
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.card-icon {
  width: 50px;
  height: auto;
  margin-bottom: 10px;
}