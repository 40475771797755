.Verification {
  display: flex;
  min-height: 100vh;
}

.ver-right {
  text-align: center;
  width: 50%;
  background-color: #ffffff;
  padding: 135px 50px 80px 50px;
}

.ver-right-title {
  text-align: center;
  font-weight: 600;
  font-size: 25px;
  color: #231F20;
  margin-bottom: 20px;
  word-wrap: break-word;
}

.verification-img {
  margin: 0px auto 70px;
}

.enter-text {
  font-weight: 500;
  font-size: 20px;
  color: #313131;
}

.enter-phone {
  margin-bottom: 50px;
  font-weight: 600;
  font-size: 20px;
  color: #86c8ef;
}

.enter-form {
  display: flex;
  flex-flow: column;
}

.enter-inp {
  width: 470px;
  height: 50px;
  background: #edf2f7;
  border: none;
  border-radius: 5px;
  font-family: inherit;
  padding: 0.375rem 1.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 30px;
}

.enter-inp::placeholder {
  font-weight: 500;
  font-size: 20px;
  color: #cbcace;
}

.send-btn {
  height: 50px;
  width: 470px;
  background-color: #60a3f3;
  border-radius: 5px;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  height: 50px;
  border: none;
  margin-bottom: 30px;
  cursor: pointer;
}

.send-otp-btn {
  height: "auto";
  /* width: 470px; */
  width: 100%;
  padding: 10px;
  max-width: fit-content;
  max-height: fit-content;
  background-color: #60a3f3;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  border: none;
  margin-bottom: 30px;
  cursor: pointer;
}

.send-text {
  font-weight: 500;
  font-size: 20px;
  color: #313131;
}

.send-link {
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  color: #85b6ee;
}

.TwofA_AuthText{
margin-bottom: 15px !important;
}

.verificationForm{
  padding: 15px 50px !important;
}
/* =========== Accordion Css =========== */
.verifiactionAccordion {
  border-top-right-radius: 20px;

}

.verifiactionAccordion .accordion-item {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: none;
  border-radius: 15px !important;
  /* border-top-right-radius: 20px;
  border-top-left-radius: 20px; */
}

.verifiactionAccordion .accordion-button {
  border-radius: 15px !important;
  background-color: #FFFFFF !important;
  border: none !important;
  box-shadow: none !important;

}
.verifiactionAccordion .accordion-button:focus {
}


/* .verifiactionAccordion .accordion-body{
  text-align: center;
} */
/* =========== Accordion Css =========== */

@media (min-width: 320px) and (max-width: 760px) {
  .dots{
    display: none;
  }
}
@media (max-width: 770px) {
  .verificationForm {
    padding: 15px !important;
  }
}
@media (max-width: 540px) {
  .onBoardingForm {justify-content: start !important;}
}
