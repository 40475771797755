.interest-balance-box {
  width: 100%;
  height: auto;
  min-height: 200px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 0px 36px 20px 18px;
}
.interest-balance-box_wrapper {
  width: 100%;
  height: 100%;
  padding: 23px 35px 24px 26px;
  display: flex;
}
.interest-balance-box-text {
  height: 100%;
  width: 200px;
  padding: 31px 20px 0px 30px;
}
.interest-balance-text {
  font-weight: 400;
  font-size: 17px;
  color: #868686;
}
.acc-balance {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.balance {
  font-weight: 600;
  font-size: 40px;
  color: #313131;
  margin-bottom: 60px;
}
.balance-currency {
  font-weight: 600;
  font-size: 15px;
  color: #313131;
  margin-top: 29px;
}
.balance::after {
  font-weight: 700;
  font-size: 20px;
}
.change-card-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.change-card-text {
  font-weight: 600;
  font-size: 15px;
  color: #313131;
  margin-right: 5px;
  margin: 0;
}
.change-btn {
  border: none;
  cursor: pointer;
  background-color: #ffffff;
}
.card-balance-btn-wrapper {
  height: 100%;
  width: 240px;
  display: flex;
  justify-content: space-between;
  padding: 0px 25px;
}
.add-founds-btn,
.send-founds-btn,
.convert-founds-btn {
  width: 74px;
  height: 100%;
  background: #e9e9e9;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column;
  font-weight: 600;
  font-size: 8px;
  color: #313131;
  padding: 20px 0px 11px 0px;
  cursor: pointer;
}
