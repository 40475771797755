
  
  .timer {
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .lblSecond {
    color: #FFFFFF !important;
    font-size: 14px;
  }
  
  .value {
    color: #FFFFFF;
    font-size: 35px;
    font-weight: bold;
  }
  
  .info {
    max-width: 360px;
    margin: 40px auto 0;
    text-align: center;
    font-size: 14px;
  }
  