.container-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #ffffff;
  border-radius: 16px;
  height: 82vh;
  margin: 10px;
  padding: 10px;
}

.image-container-style {
  width: 500px;
  max-width: 100%;
}

.image-style {
  width: 100%;
}

.title-style {
  color: #231f20;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
}

.description-style {
  color: #adb6c0;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}

@media (max-width: 576px) {
  .title-style {
    font-weight: 600;
    font-size: 30px;
  }

  .description-style {
    font-weight: 400;
    font-size: 18px;
  }
}

.email-style {
  color: #006ff4;
}
