.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  margin: 10px 0px;
}
@media only screen and (min-width: 1248px) and (max-width: 1281px) {
  .footer { margin: -10px 0px;}
}

.footer-p {
  margin: 0 !important;
  font-family: "Montserrat";
  color: #868686 !important;
  margin-left: 5px;
}

.footer-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-a {
  margin-right: 12px;
  text-decoration: none;
}
.privacy-policy-footer-text {
  cursor: pointer;
  margin-right: 10px;
  font-family: "Montserrat";
  color: #0a58ca !important;
}
.privacy-policy-vr {
  color: #0a58ca !important;
  padding-right: 10px;
}
.pop-over-text {
  cursor: pointer;
  font-family: "Montserrat";
  color: #0a58ca !important;
  margin-right: 10px;
}
.transaction-fees {
  display: flex;
  justify-content: center;
}
.transaction-text {
  font-size: 10px;
  font-weight: bold;
}
.fees-table-summary {
  font-size: 10px;
}
