/* .modal-body{
  max-height: 100vh; 
  overflow-y: auto !important;
  
} */
.modalFooter{
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.date_input_style {
  height: 40px;
  max-width: 100%;
  border: 1px solid #D7F0FF;
  font-weight: 500;
  font-size: 14px;
  color: #231F20;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('../../assets//images/revamp/calender-icon.svg') no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

.mui-datatable-container table {
  width: 100% !important;
  overflow-x: auto;
}

.title {
  font-size: 24px;
  margin: 10px 0;
}

.modal-header-cont{
  padding-top: 5px;
  
}
.modal-body{
  padding: 30px;
}
.span-dates{margin: 0px 0px 2px 3px;}

.inner__contentStatement{
  padding: 20px !important;
  margin-left: 0px !important;
}

.text{
  font-family: "Montserrat";
  color: #1A1A1A;
  font-size: 16px;
  line-height: 2.5;
}
.text-table{
  font-family: "Montserrat";
  color: #1A1A1A;
  font-size: 18px;
}
.table-heading{
  font-family: "Montserrat";
  color: #1A1A1A;
  font-size: 18px;
}
.table-data{
  font-family: "Montserrat";
  color: #1A1A1A;
  font-size: 17px;
}
.countryFlag span {
  border-radius: 50%;
  height: 18px;
  width: 17.79px;
  background-position: center;
}
/* .tbl-ref{
  font-family: "Montserrat";
  color: #1A1A1A;
  font-size: 18px;
} */

/* Statement Table */
.statement-SectionBackground {
  background: rgb(255, 255, 255);
    /* border-radius: 15px; */
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    /* padding-top: 0px !important; */
    
}

.statementTblHeading {
  font-family: "Montserrat", sans-serif !important;
  font-size: 24px;
  font-weight: 600;
  color: #231F20;
  margin-left: 10px;
}

.statementTable .tss-1x5mjc5-MUIDataTable-root{
  box-shadow: none;
  }
  
  .statementTable .MuiToolbar-root {
    padding: 0;
  }
  
  .statementTable .tss-1x5mjc5-MUIDataTable-root table thead {
    border-bottom: 1px solid #DEE2E6;
  }
  .statementTable .tss-1x5mjc5-MUIDataTable-root table tr th ,
  .statementTable .tss-1x5mjc5-MUIDataTable-root table tr td {
    border: none;
    font-size: 18px;
  }
  .statementTable .tss-1x5mjc5-MUIDataTable-root table tr th div {
    width: max-content !important;
  }
  
  .statementTable .tss-1x5mjc5-MUIDataTable-root table tr th button{
    font-size: 18px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif !important;
    text-transform: capitalize;
    color: #231F20;
  }
  .statementTable .tss-1x5mjc5-MUIDataTable-root table tr th button div:nth-child(1){
    display: flex;
    /* align-items: baseline; */
  }
  .statementTable .tss-1x5mjc5-MUIDataTable-root table tr th{
    /* background-color: #006FF4;
    color: #FFFFFF; */
    font-size: 18px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif !important;
  }
  
  .statementTable .tss-1x5mjc5-MUIDataTable-root table tr th:first-child{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  }
  
  .statementTable .tss-1x5mjc5-MUIDataTable-root table tr th:last-child{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  }

  .statementTable tfoot tr td .MuiToolbar-root{
    display: flex;
    align-items: baseline;
  }
  
  .statementTable .tss-qbo1l6-MUIDataTableToolbar-actions button svg,
  .statementTable .tss-qbo1l6-MUIDataTableToolbar-actions button img{
    width: 30px;
    height: 30px;
  }
  
  /* .statementTable .tss-1x5mjc5-MUIDataTable-root table tfoot{
    display: none;
  } */
/* Statement Table */

.btnViewStatement{
  width: 35%;
}
.period{
  font-size: 18px;
}
.history{
  font-size:24px;
   margin-bottom: 20px;
}
.modal-title{
  font-weight: 600;
  font-size:24px !important; 
  /* margin-bottom:20px;  */
}
.statement-copyright{
  color: #ADB6C0;
  font-size: 18px;
  font-weight: 400;
  } 
  .statement-root-row{ padding: 1.5rem; }
  .btn-generate-pdf-row {width: 40%;}
  .date-selector-root-col .date_input_style {min-width: 100%;}
  .css-1x51dt5-MuiInputBase-input-MuiInput-input {font-family: 'Montserrat' !important;}

  

/* media queries */
@media screen and (max-width: 1200px) {
  .my-modal { min-width: 90% }
}
@media screen and (max-width: 1150px) {
  /* .date_input_style {
    max-width: 130px;
  } */
  .span-dates{
    margin-right: 0px;
    font-size: 13px;
  }
}
@media screen and (max-width: 1030px) {
  /* .date_input_style {
    max-width: 120px;
  } */
  .span-dates{
    margin-right: 0px;
    font-size: 12px;
  }
}
@media screen and (max-width: 992px) {
  /* .date_input_style {
    max-width: 150px;
  } */
  .period{
    font-size: 14px;
  }
  .history{
    font-size:20px;
     margin-bottom: 20px;
  }
  .span-dates{
    margin-right: 10px;
    font-size: 14px;
  }
}
@media screen and (max-width: 992px) {
  .modal-body {padding: 5px;}
  .table-heading { font-size: 12px;padding: 10px 4px !important; }
  .table-data{ font-size: 12px; }
  .tbl-date {min-width:80px;}
  .tbl-paid {min-width:70px;}
  .tbl-data-padding {padding: 8px 2px !important;}
  .resClass { padding: 0px !important;}
}
@media screen and (max-width: 576px) {
  .statement-root-row{ padding: 0.6rem; }
  .span-dates { font-size: 10px;}
  .date-selector-root-col {padding: 0px 2px 10px 3px;}
  /* input[type="date"]::-webkit-calendar-picker-indicator {
    
  } */
  .statementTable .tss-1x5mjc5-MUIDataTable-root table tr th ,
  .statementTable .tss-1x5mjc5-MUIDataTable-root table tr td {font-size: 8px;}
  .statementTable .tss-1x5mjc5-MUIDataTable-root table tr th button{font-size: 10px;}
  .statementTable .tss-1x5mjc5-MUIDataTable-root table tr th {padding-bottom: 0px;}
  .modal-title{
    font-size:18px !important; 
    font-weight: 600;
  }
  .text{font-size: 12px;}
  .history{margin-left: 15px; margin-bottom: 0px;}
  .period{font-size: 10px;}
  .table-data-created{ font-size: 14px; font-weight: 600;}
  .statement-copyright{font-size: 12px;}
  .btnViewStatement, .btn-generate-pdf-row {width: 100%;}
  .statement-paidIn-paidOut-icon{width: 7px !important; height: 11px !important;}
  .countryFlag span {
    height: 12px;
    width: 12px;
    margin-left: 4px !important;
  }
  /* paginaton responsiveness */
  .custom-pagination-root-nav ul {padding: 0px;}
  .custom-pagination-root-nav ul li div {padding: 0px !important; min-width: 26px; height: 26px; font-size: 12px;}
  .tss-212che-MUIDataTableToolbar-left .css-1dkc8sk-MuiFormControl-root-MuiTextField-root {padding: 0px;}
  .tss-qbo1l6-MUIDataTableToolbar-actions {flex:1 0 auto !important;}
  .date_input_style      {
    height: 40px !important; 
    line-height: 15px !important;  
    display: flex !important; 
    align-items: center !important; 
    justify-content: center; 
    font-size: 10px;
    padding: 0px !important;
    text-align: center !important;
  }
  .btn-account-selector  {height: 40px !important; font-size: 11px !important; border-radius: 10px; }
  .date-selector-root-col .css-1kpubvg-MuiButtonBase-root-MuiButton-root {height: auto !important;}
  .statementTable .tss-qbo1l6-MUIDataTableToolbar-actions button {padding: 5px;}  
  .statementTable .tss-212che-MUIDataTableToolbar-left{padding-top: 0px !important;}
  .statementTable .tss-212che-MUIDataTableToolbar-left svg, 
  .statementTable .tss-1fz5efq-MUIDataTableToolbar-left .tss-52j81d-MUIDataTableSearch-clearIcon { 
    margin: 0px !important; 
    padding: 0px !important; 
    width: 16px !important;
    height: 16px !important;
  }
}

@media screen and (max-width: 502px) {
  .table-heading { font-size: 12px; padding: 10px 4px !important;  }
  .tbl-date {min-width:85px;}
  .statementTable .tss-qbo1l6-MUIDataTableToolbar-actions button svg,
  .statementTable .tss-qbo1l6-MUIDataTableToolbar-actions button img{
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 478px) {
  .table-heading { font-size: 10px; padding: 10px 3px !important;}
  .table-data{ font-size: 10px;}
  .tbl-ref {padding: 10px 4px !important; }
  .tbl-date {min-width:75px;}
  .tbl-data-padding {padding: 5px 0px !important;}
  .statementTblHeading {font-size: 20px;}
}

@media screen and (max-width: 450px) {
  .modal-body {padding: 3px;}
  .table-heading { font-size: 8px; padding: 3px; }
  .table-data{ font-size: 8px;}
  .account-detail-row {margin-left: 5px;}
  .tbl-date {min-width:50px;}
}

@media screen and (max-width: 420px) {
  .modal-body {padding: 3px;}
  .table-heading { font-size: 8px; }
  .table-data{ font-size: 8px; }
  .tbl-paid {min-width:50px;}
}

@media screen and (max-width: 400px) {
  .modal-body {padding: 2px;}
  .table-heading { font-size: 8px;  }
  .table-data{ font-size: 8px;}
  .statementTable .tss-qbo1l6-MUIDataTableToolbar-actions button svg,
  .statementTable .tss-qbo1l6-MUIDataTableToolbar-actions button img{
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 760px) {
  .modal-header-cont{
    padding-top: 25px;
  }
}

@media screen and (max-width: 575px) {
  .modal-header-cont{
    padding-top: 35px;
  }
  /* Footer Pagination */
  tfoot .MuiTableCell-root {
    padding: 0px 14px 0px 14px;
  
  }
  .MuiTablePagination-root .MuiToolbar-root.MuiToolbar-gutters {
    padding-left: 0px;
  }
  .MuiTablePagination-root .MuiInputBase-root.MuiInputBase-colorPrimary {
    margin-right: 8px;
  }
  .MuiTablePagination-actions {
    margin-left: 8px !important; 
  }
  .MuiTablePagination-actions .MuiButtonBase-root.Mui-disabled {
    padding: 8px 0;
  
  }
  .css-pdct74-MuiTablePagination-selectLabel, .css-levciy-MuiTablePagination-displayedRows {
    margin: 0;
  }
}


